import LazyLoad from "vanilla-lazyload";

/**
 * Initiate Lazyload on images with class "lazy".
 */
const LazyLoader = new LazyLoad({
  elements_selector: ".lazy",
  // ... more custom settings?
});

window.onload = () => {
  LazyLoader.loadAll();
};

export default LazyLoader;
