import $ from "jquery";

$(window).on("load scroll", function(e) {
  var scroll = $(window).scrollTop();

  if (scroll > 100) {
    $("body").addClass("is-scrolling");
  } else {
    $("body").removeClass("is-scrolling");
  }
});
