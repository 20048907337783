import Cookies from "js-cookie";
import $ from "jquery";

var cookieBar = $(".site-cookiebar");
var newsletterBlock = $("section.newsletter-block");
var getCookiebarHeight = $(".site-cookiebar").outerHeight();

if (Cookies.get("site_agreement_cookie_673")) {
  // cookieBar.hide();
  // console.log('have cookie');
} else {
  $(window).on("load", function () {
    cookieBar.slideDown();
    $(newsletterBlock).css("bottom", getCookiebarHeight + "px");
  });

  $(window).on("resize", function () {
    if (
      $(window).width() > 767 &&
      $(window).height() > 700 &&
      newsletterBlock.length
    ) {
      $(newsletterBlock).css("bottom", getCookiebarHeight + "px");
    }
  });
}

// when user agree on cookie bar
$("#accept-cookies").on("click", function (e) {
  e.preventDefault();
  Cookies.set("site_agreement_cookie_673", 1, { expires: 365, path: "/" });
  $(cookieBar).slideUp();

  if (
    $(window).width() > 767 &&
    $(window).height() > 700 &&
    newsletterBlock.length
  ) {
    $(newsletterBlock).css("bottom", "0px");
  }

  $(window).on("load resize", function () {
    if (
      $(window).width() > 767 &&
      $(window).height() > 700 &&
      newsletterBlock.length
    ) {
      $(newsletterBlock).css("bottom", "0px");
    }
  });
});
