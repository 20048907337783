import $ from "jquery";
import "./header.js";
import "./nav.js";
import "./video.js";
import "./lazyload.js";
import "./testimonial-slider.js";
import "./loadmore-image-list.js";
import "./banner-newsletter.js";
import "./cookiebar.js";
import "./popup.js";
import "./loadmore.js";

jQuery(function ($) {
  $(".gform_wrapper form").on("submit", function (e) {
    console.log('Hello');
    b = $("button.button-primary.gform_button", this);
    b.attr("disabled", true);
    b.css("background", "darkgray");
    b.css("color", "black");
    b.val(disable_submit.processing_text);
  });
});
