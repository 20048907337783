import $ from "jquery";

$(window).on("load resize", function(e) {
  e.preventDefault();
  var spacing = $(".site-header .container").offset().left;
  var headerNavigation = $(".site-header__menu-innerwrapper");
  var screenWidth = $(this).width();

  if (screenWidth > 767) {
    headerNavigation.css("padding-right", "7rem");

    if (screenWidth > 1023) {
      headerNavigation.css("padding-right", spacing);
    }
  } else {
    headerNavigation.css("padding-right", "2rem");
  }
});

$(".menu-toggle").on("click", function(e) {
  e.preventDefault();
  $("body").toggleClass("nav-active");
  $("body.nav-active .site-header__menu-wrapper .overlay").on("click", function(
    e,
  ) {
    $("body").removeClass("nav-active");
  });
});

$("svg.dropdown").on("click", function(e) {
  e.preventDefault();
  var dropdownMenu = $(this)
    .parent(".site-header__menu-item")
    .find(".site-header__menu-dropdown");
  var menuParent = $(this).parent(".site-header__menu-item");

  dropdownMenu.slideToggle();
  // console.log(menuParent.length);
  dropdownMenu.parent(".site-header__menu-item").toggleClass("dropdown-active");
  // dropdownMenu.parent('.menu-item.menu-item-has-children').css('display', 'css');

  menuParent
    .siblings("li.menu-item-has-children")
    .removeClass("dropdown-active");
  menuParent
    .siblings("li.menu-item-has-children")
    .find("ul.site-header__menu-dropdown")
    .slideUp();
});
