import $ from 'jquery';

$('a.loadmore-list-item').on('click', function(e) {
  e.preventDefault();
  var button = $(this).parent('.button-wrapper');
  var parentWrapper = $(this).parents('section.press-image');
  var postList = parentWrapper.find('.press-image__listings');
  var postItem = postList.find('li.press-image__item');

  if (postItem.length > 9) {
    if(postItem.hasClass('is-hidden')) {
      $(postItem).each(function(i) {
        button.fadeOut();
        $(this).delay(i * 120).fadeIn('200');
        $(this).removeClass('is-hidden');
        // $(this).delay(i * 80).removeClass('is-hidden');
      });
    }
  }
});
