import LazyLoader from "./lazyload";
import $ from "jquery";

var animatedLoadingSVG =
  '<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid" viewBox="0 0 100 100" class="loading-svg"><circle cx="50" cy="50" r="35" fill="none" stroke="#fff" stroke-dasharray="164.93361431 56.97787144" stroke-width="10" transform="rotate(303.617 50 50)"><animateTransform attributeName="transform" dur="1s" keyTimes="0;1" repeatCount="indefinite" type="rotate" values="0 50 50;360 50 50"/></circle></svg>';

// console.log($('a.ajax-post-loader').length());

$("a.ajax-post-loader").on("click", function (e) {
  e.preventDefault();
  var button = $(this);
  var postType = button.data("post-type");
  var parent = button.parents("section.listing-block");
  var postWrapper = parent.find(".listing-block__wrapper-posts");
  var paged = button.attr("data-paged");
  var downSVG = button.find("svg.loadmore-down-svg");

  if (postType && postWrapper) {
    $.ajax({
      url: ajaxurl,
      method: "POST",
      data: {
        post_type: postType,
        paged: paged,
        action: "loadmore",
      },
      beforeSend: function () {
        $("body").addClass("is-loading");
        button.append(animatedLoadingSVG);
        downSVG.hide();
      },
      success: function (response) {
        if (response) {
          var responseData = $(response.html).hide();
          // console.log(responseData);
          button.attr("data-paged", response.paged);
          postWrapper.append(responseData);

          $(responseData).each(function (i) {
            $(this)
              .delay(i * 100)
              .fadeIn("200");
          });

          // reinitiate LazyLoader so loaded articles work
          if (LazyLoader) {
            LazyLoader.loadAll();
          }

          downSVG.show();
          button.find("svg.loading-svg").hide();

          if (response.hide_more == true) {
            button.hide();
            // console.log(button);
          }
        }

        $("body").removeClass("is-loading");
      },
    });
  }
});
