import $ from "jquery";

class Player {
  constructor() {
    this.youtubePlayers = []; // For Youtube Players
    this.currentPlayerId;
    this.injectYoutubeScript();
    this.vimeoPlayers = []; // For Vimeo Players
    this.injectVimeoScript();
  }

  injectYoutubeScript() {
    var that = this;
    var ytPlayers = $("iframe.video--youtube");

    if (ytPlayers.length) {
      var ytag = document.createElement("script");
      ytag.src = "//www.youtube.com/player_api";
      var yScriptTag = document.getElementsByTagName("script")[0];
      yScriptTag.parentNode.insertBefore(ytag, yScriptTag);
      that.handleInlineVideo();
      that.youTubeIframeAPIReady();
    }
  }

  handleInlineVideo() {
    var that = this;
    $(".js-play-video").on("click", function () {
      var playBtn = $(this);
      that.handleVideoClasses();
      var playerBlock = playBtn.parents(".video-block__video-wrapper");
      var playerIframe = playerBlock.find("iframe");
      var iframeSrc = $(playerIframe).data("src");
      playerIframe.attr("src", iframeSrc);
      $(playerBlock).addClass("is-visible");
    });
  }

  handleVideoClasses() {
    var activePlayers = $(".video-block__video-wrapper.is-visible");
    $.each(activePlayers, function () {
      var videoIframe = $(this).find("iframe");
      videoIframe.attr("src", "");
      $(this).removeClass("is-visible");
    });
  }

  youTubeIframeAPIReady() {
    var that = this;
    window.onYouTubePlayerAPIReady = function () {
      $(".js-show-video--youtube").on("click", function () {
        var playBtn = $(this);
        that.handleVideoClasses();
        var videoId = $(this).attr("data-video-id");
        that.currentPlayerId = videoId;

        var playerBlock = playBtn.parents(".video-block__video-wrapper");
        var mainVideoBlock = playBtn.parents("section.video-block");

        var videoDataURL = $("#" + videoId).data("src");

        if (videoDataURL !== $("#" + videoId).attr("src")) {
          $("#" + videoId).attr("src", videoDataURL);
        }

        $("body").addClass("has-video-popup");
        playerBlock.addClass("is-active");
        mainVideoBlock.addClass("video-active");

        if (!that.youtubePlayers[videoId]) {
          that.youtubePlayers[videoId] = new YT.Player(videoId, {
            events: {
              // call this function when player is ready to use
              onReady: that.onYoutubePlayerReady(videoId),
            },
          });
        }
      });
    };
  }

  onYoutubePlayerReady(videoId) {
    var that = this;

    $(".js-hide-video--youtube").on("click", function () {
      var closeBtn = $(this);
      var videoIframe = closeBtn
        .parent(".video-block__inner-wrapper")
        .find(".video-block__video iframe");
      var playerBlock = closeBtn.parents(".video-block__video-wrapper");
      var mainVideoBlock = closeBtn.parents("section.video-block");

      $("body").removeClass("has-video-popup");
      playerBlock.removeClass("is-active");
      mainVideoBlock.removeClass("video-active");

      if (videoIframe.length) {
        var videoId = videoIframe.attr("id");
        if (that.youtubePlayers[videoId]) {
          // Pause youtube player
          that.youtubePlayers[videoId].pauseVideo();
        }
      }
    });
  }

  injectVimeoScript() {
    var that = this;
    var vmPlayers = jQuery("iframe.video--vimeo");

    if (vmPlayers.length) {
      var vmtag = document.createElement("script");
      vmtag.src = "//player.vimeo.com/api/player.js";
      var vmScriptTag = document.getElementsByTagName("script")[0];
      vmScriptTag.parentNode.insertBefore(vmtag, vmScriptTag);
      that.vimeoIframeAPIReady();
    }
  }

  vimeoIframeAPIReady() {
    var that = this;
    jQuery(".js-show-video--vimeo").on("click", function () {
      var playBtn = jQuery(this);
      var videoId = jQuery(this).attr("data-video-id");

      var playerBlock = playBtn.parents(".video-block__video-wrapper");
      var mainVideoBlock = playBtn.parents("section.video-block");

      jQuery("body").addClass("has-video-popup");
      playerBlock.addClass("is-active");
      mainVideoBlock.addClass("video-active");

      that.currentPlayerId = videoId;
      if (!that.vimeoPlayers[videoId]) {
        var src = jQuery("#" + videoId).attr("data-src");
        jQuery("#" + videoId).attr("src", src);

        that.vimeoPlayers[videoId] = new Vimeo.Player(videoId);

        jQuery(".js-hide-video--vimeo").on("click", function () {
          var videoIframe = playerBlock.find(".video--vimeo");
          if (videoIframe.length) {
            if (that.vimeoPlayers[videoId]) {
              that.vimeoPlayers[videoId].pause();

              jQuery("body").removeClass("has-video-popup");
              playerBlock.removeClass("is-active");
              mainVideoBlock.removeClass("video-active");
            }
          }
        });
      }
    });
  }
}

new Player();
