import $ from "jquery";
import Cookies from "js-cookie";

var newsletterBlock = $("section.newsletter-block");
var CookieName = "BannerNewsletterTrue";

if (Cookies.get(CookieName)) {
  newsletterBlock.addClass("cookie-active");
} else {
  newsletterBlock.removeClass("cookie-active");
  if (newsletterBlock.length) {
    $(".newsletter-block__close").on("click", function (e) {
      e.preventDefault();
      var bannerNewsletterCloser = $(this);
      var newsletterBlock = $(this).parents("section.newsletter-block");
      newsletterBlock.addClass("is-hidden is-triggered");

      setTimeout(function () {
        Cookies.set(CookieName, 1, { expires: 30, path: "/" });
        if (Cookies.get(CookieName)) {
          newsletterBlock.addClass("cookie-active");
        }
      }, 3000);
    });

    $(window).on("load resize scroll", function (event) {
      var newsletterBlockTopOffset = $(window).scrollTop();
      var doc = document.documentElement;
      var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
      var footerTopOffset = $("footer.site-footer").offset().top;
      var footerHeight = $("footer.site-footer").outerHeight();

      var mainOffsetVal =
        newsletterBlockTopOffset + newsletterBlock.outerHeight() + footerHeight;

      if ($(window).width() > 767 && !$(newsletterBlock).hasClass("is-triggered")) {
        if (mainOffsetVal > footerTopOffset) {
          $(newsletterBlock).addClass("is-hidden");
        } else {
          $(newsletterBlock).removeClass("is-hidden");
        }
      }
    });

    // Handle Newsletter form on mobile
    if ($(window).width() < 767) {
      $(window).on("load", () => {
        setTimeout(function () {
          $(newsletterBlock).addClass("is-visible");
        }, 1000);
      });
    }
  }
}
