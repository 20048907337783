import $ from "jquery";
import jQueryBridget from "jquery-bridget";
import Flickity from "flickity";
jQueryBridget("flickity", Flickity, $);

$("section.testimonial-list").each(function() {
  if ($(this).length) {
    var slider = $(this).find(".testimonial-list__item-wrapper");
    var sliderItem = slider.find(".testimonial-list__item").length;
    var cellButtonGroup = $(this).find(".testimonial-list__button-controls");
    var cellButtons = $(this).find(".testimonial-list__control");
    var prevButton = $(this).find(".testimonial-list__list-button.prev");
    var nextButton = $(this).find(".testimonial-list__list-button.next");

    // console.log(cellButtonGroup.length);

    if (sliderItem > 1) {
      // Init Flickity
      var carousel = $(slider).flickity({
        cellAlign: "left",
        cellSelector: '.testimonial-list__item',
        prevNextButtons: false,
        draggable: false,
        pageDots: false,
        on: {
          change: function(index) {
            cellButtons.filter(".is-selected").removeClass("is-selected");
            cellButtons.eq(flkty.selectedIndex).addClass("is-selected");

            if (index == 0) {
              prevButton.fadeOut();
            } else {
              prevButton.fadeIn();
            }
          },
        },
      });

      // Flickity instance
      var flkty = carousel.data("flickity");

      carousel.on('settle.flickity', function() {
        if (flkty.selectedIndex == flkty.cells.length - 1) {
          // console.log('Last one');
        }
      });

      // select cell on button click
      $(cellButtons).on("click", function() {
        var index = $(this).index();
        carousel.flickity("select", index);
      });

      // previous slide
      $(prevButton).on("click", function() {
        carousel.flickity("previous");
        // $(this).addClass("is-selected");
      });

      // next Slide
      $(nextButton).on("click", function() {
        carousel.flickity("next");
      });
    }
  }
});
