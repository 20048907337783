import $ from "jquery";

$(document).keyup(function(e) {
  if (e.key === "Escape") {
    if ($("body").hasClass("is-loading, error-401")) {
      // While site is loading and error do nothing do not open popups
    } else {
      if ($("body").hasClass("popup-active")) {
        $("body").removeClass("popup-active");
      }
    }
  }
});

$(document).on("click", 'a[href="#gdpr-popup"], a.popup-activator', function(
  e,
) {
  e.preventDefault();
  $("body").addClass("popup-active");

  // Get gForm ID
  var selectedForm = $(this)
    .parents("form")
    .attr("id");

  if (selectedForm) {
    // When GDPR Accept button on click
    $(document).on("click", 'a[href="#accept_gdpr"]', function(e) {
      e.preventDefault();
      // check the gdpr checkbox active will only work if the form  GDPR checkbox have class called 'is-gdpr'
      var gdprCheckbox = $("body")
        .find("#" + selectedForm)
        .find("li.gfield.is-gdpr input:visible");
      gdprCheckbox.prop("checked", true);
      closePopups();
    });
  }
});

$(document).on("click", ".popup-block__overlay, .popup-closer", function(e) {
  e.preventDefault();
  closePopups();
});

// Close popups
function closePopups() {
  $("body").removeClass("popup-active");
}
